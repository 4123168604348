@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

.contact {
    color: #ffffff;
    background-color: #273898;
    padding: 8px;
    font-family: 'Lato', Helvetica, 'sans-serif'
}

.contact a {
    color: #ffffff;
}
